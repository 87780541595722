export const FOLDERS_DATA = [
	{
		id: 1,
		name: "Spread Sheets",
		files: "312",
	},
	{
		id: 2,
		name: "Documents",
		files: "4532",
	},
	{
		id: 3,
		name: "Downloaded Files",
		files: "15876",
	},
	{
		id: 4,
		name: "2023 Projects",
		files: "40",
	},
	{
		id: 5,
		name: "RAW Pictures",
		files: "543",
	},
	{
		id: "6",
		name: "Video Scripts",
		files: "07",
	},
	{
		id: "7",
		name: "Development",
		files: "4353",
	},
	{
		id: "8",
		name: "Blog Articles",
		files: "34",
	},
];

export const FILES_DATA = [
	{
		id: 7,
		type: "code",
		icon: "ph-brackets-curly",
		name: "package.json",
		size: "229 bytes",
		color: "bg-warning",
		date: "18/07/2024 11:32:41",
	},
	{
		id: 8,
		type: "doc",
		icon: "ph-article",
		name: "cover_letter.doc",
		size: "32 kb",
		color: "bg-primary",
		date: "21/02/2024 08:48:13",
	},
	{
		id: 9,
		type: "audio",
		icon: "ph-headset",
		name: "recording_09-10.mp3",
		size: "2.32 mb",
		color: "bg-success",
		date: "10/02/2024 11:25:08",
	},
	{
		id: 10,
		type: "image",
		img: "2",
		name: "wallpaper-widescreen.jpg",
		size: "900 kb",
		date: "23/03/2024 11:59:33",
	},
	{
		id: 11,
		type: "image",
		img: "1",
		name: "screenshot-98731.jpg",
		size: "653 kb",
		date: "17/02/2024 03:30:34",
	},
	{
		id: 12,
		type: "video",
		icon: "ph-play-circle",
		name: "animation-trailer.mp4",
		size: "43.86 mb",
		color: "bg-pink",
		date: "07/05/2024 15:56:40",
	},
	{
		id: 13,
		type: "pdf",
		icon: "ph-file-pdf",
		name: "instructions.pdf",
		size: "205.5 kb",
		color: "bg-danger",
		date: "25/12/2024 11:17:53",
	},
	{
		id: 14,
		type: "spreadsheet",
		icon: "ph-grid-four",
		name: "signup-list.xls",
		size: "987.23 kb",
		color: "bg-purple",
		date: "18/01/2024 21:10:54",
	},
	{
		id: 15,
		type: "image",
		img: "13",
		name: "sample-preview.jpg",
		size: "2.34 mb",
		date: "18/12/2024 23:04:12",
	},
	{
		id: 16,
		type: "text",
		icon: "ph-text-a-underline",
		name: "detailed-notes.txt",
		size: "54.5 kb",
		color: "bg-indigo",
		date: "05/11/2024 11:31:34",
	},
	{
		id: 17,
		type: "presentation",
		icon: "ph-presentation-chart",
		name: "presentation.ppt",
		size: "10.12 mb",
		color: "bg-info",
		date: "30/09/2024 17:50:53",
	},
	{
		id: 18,
		type: "zip",
		icon: "ph-file-archive",
		name: "sample-icons.zip",
		size: "98.44 mb",
		color: "bg-secondary",
		date: "08/01/2024 07:09:13",
	},
	{
		id: 19,
		type: "other",
		icon: "ph-file",
		name: "data-list.yaml",
		size: "763.54 kb",
		color: "bg-lime",
		date: "31/05/2024 02:52:29",
	},
	{
		id: 20,
		type: "image",
		img: "19",
		name: "photoshop-output.jpg",
		size: "635 kb",
		date: "30/01/2024 00:54:57",
	},
	{
		id: 21,
		type: "image",
		img: "24",
		name: "736183293836100.jpg",
		size: "75 kb",
		date: "13/03/2024 17:24:03",
	},
	{
		id: 22,
		type: "image",
		img: "18",
		name: "00928921221212993283.jpg",
		size: "75 kb",
		date: "29/03/2024 11:47:12",
	},
];
