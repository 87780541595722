const _temp0 = require("../../../img/gallery/thumbs/1.jpg");
const _temp1 = require("../../../img/gallery/thumbs/2.jpg");
const _temp2 = require("../../../img/gallery/thumbs/3.jpg");
const _temp3 = require("../../../img/gallery/thumbs/4.jpg");
const _temp4 = require("../../../img/gallery/thumbs/5.jpg");
const _temp5 = require("../../../img/gallery/thumbs/6.jpg");
const _temp6 = require("../../../img/gallery/thumbs/7.jpg");
const _temp7 = require("../../../img/gallery/thumbs/8.jpg");
const _temp8 = require("../../../img/gallery/thumbs/9.jpg");
const _temp9 = require("../../../img/gallery/thumbs/10.jpg");
const _temp10 = require("../../../img/gallery/thumbs/11.jpg");
const _temp11 = require("../../../img/gallery/thumbs/12.jpg");
const _temp12 = require("../../../img/gallery/thumbs/13.jpg");
const _temp13 = require("../../../img/gallery/thumbs/14.jpg");
const _temp14 = require("../../../img/gallery/thumbs/15.jpg");
const _temp15 = require("../../../img/gallery/thumbs/16.jpg");
const _temp16 = require("../../../img/gallery/thumbs/17.jpg");
const _temp17 = require("../../../img/gallery/thumbs/18.jpg");
const _temp18 = require("../../../img/gallery/thumbs/19.jpg");
const _temp19 = require("../../../img/gallery/thumbs/20.jpg");
const _temp20 = require("../../../img/gallery/thumbs/21.jpg");
const _temp21 = require("../../../img/gallery/thumbs/22.jpg");
const _temp22 = require("../../../img/gallery/thumbs/23.jpg");
const _temp23 = require("../../../img/gallery/thumbs/24.jpg");
module.exports = {
  "1": _temp0,
  "2": _temp1,
  "3": _temp2,
  "4": _temp3,
  "5": _temp4,
  "6": _temp5,
  "7": _temp6,
  "8": _temp7,
  "9": _temp8,
  "10": _temp9,
  "11": _temp10,
  "12": _temp11,
  "13": _temp12,
  "14": _temp13,
  "15": _temp14,
  "16": _temp15,
  "17": _temp16,
  "18": _temp17,
  "19": _temp18,
  "20": _temp19,
  "21": _temp20,
  "22": _temp21,
  "23": _temp22,
  "24": _temp23
}