export const DATA = [
	{
		title: "Sales leads play a crucial role in business development",
		page: "invoice/invoice-2012-10",
	},
	{
		title: "Effective lead management enhances sales performance",
		page: "mail/sent",
	},
	{
		title: "Lead generation strategies vary based on industry",
		page: "messages/archived",
	},
	{
		title: "Successful lead nurturing requires consistent effort",
		page: "mail/inbox",
	},
	{
		title: "Converting a sales lead can be a rewarding process",
		page: "presentation/demos",
	},
	{
		title: "Lead scoring helps identify potential opportunities",
		page: "reports/spreadsheets",
	},
	{
		title: "Lead capture forms are widely used in digital marketing",
		page: "users/collaborators",
	},
	{
		title: "Sales lead databases store valuable contact details",
		page: "documents/misc",
	},
	{
		title: "Quality leads are a valuable asset for any company",
		page: "todo/upcoming",
	},
	{
		title: "Exploring new lead sources can boost sales efforts",
		page: "dashboard/website-analytics",
	},
	{
		title: "Lead qualification is vital for efficient sales operations",
		page: "calendar/upcoming",
	},
	{
		title: "A strong sales team can effectively handle leads",
		page: "reports/sales-completed",
	},
	{
		title: "Follow-up is key to converting cold leads into clients",
		page: "calendar/events",
	},
];
