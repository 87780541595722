const _temp0 = require("../../../img/products/1.jpg");
const _temp1 = require("../../../img/products/2.jpg");
const _temp2 = require("../../../img/products/3.jpg");
const _temp3 = require("../../../img/products/4.jpg");
const _temp4 = require("../../../img/products/5.jpg");
const _temp5 = require("../../../img/products/6.jpg");
const _temp6 = require("../../../img/products/7.jpg");
const _temp7 = require("../../../img/products/8.jpg");
const _temp8 = require("../../../img/products/9.jpg");
module.exports = {
  "1": _temp0,
  "2": _temp1,
  "3": _temp2,
  "4": _temp3,
  "5": _temp4,
  "6": _temp5,
  "7": _temp6,
  "8": _temp7,
  "9": _temp8
}