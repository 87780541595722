//---------------------------------------------------
// Colors
//---------------------------------------------------
export const COLORS = {
	white: "#ffffff",
	transparent: "rgba(0,0,0,0)",
	blue: "#2392f9",
	indigo: "#5c6bc0",
	purple: "#e26cf5",
	pink: "#f06292",
	red: "#fd7171",
	orange: "#fea84c",
	yellow: "#ffeb3b",
	green: "#10b981",
	teal: "#33b6a9",
	cyan: "#26c6da",
};

//---------------------------------------------------
// Find out the platform is MAC or not
//---------------------------------------------------
export const isMac = () => {
	const platform = navigator?.userAgentData?.platform || navigator?.platform;
	return platform.toUpperCase().indexOf("MAC") >= 0;
};

//---------------------------------------------------
// Return CSS variable
// This is helpful for dark/light theme modes
//---------------------------------------------------
export const cssVar = (variable) => {
	return getComputedStyle(document.body).getPropertyValue(variable).trim();
};

//---------------------------------------------------
// Even row background for lists
//---------------------------------------------------
export const evenRow = (index, highlightClass = "bg-highlight") => {
	let rowClass = "";
	if (index % 2 === 0) {
		rowClass = highlightClass;
	} else {
		rowClass = "";
	}

	return rowClass;
};

//---------------------------------------------------
// Throttle function
//---------------------------------------------------
export const throttle = (fn, delay) => {
	let lastCall = 0;
	return function (...args) {
		const now = new Date().getTime();
		if (now - lastCall >= delay) {
			lastCall = now;
			fn(...args);
		}
	};
};

//---------------------------------------------------
// Avatar
//---------------------------------------------------
const AVATAR_BASE_CLS = `w-8 h-8 rounded-circle d-grid place-content-center`;
const AVATAR_BORDER_BASE_CLS = `w-10 h-10 rounded-circle border border-opacity-50 d-grid place-content-center border me-3 flex-shrink-0`;

export const avatarCap = (cap = "A", bg = "bg-active", borderColor = "") => {
	return `<div class="text-uppercase ${AVATAR_BORDER_BASE_CLS} ${borderColor}">
				<div class="text-invert fw-bold ${AVATAR_BASE_CLS} ${bg}">${cap}</div>
			</div>`;
};

export const avatarIcon = (icon = "ph-user", bg = "bg-active", borderColor = "") => {
	return `<div class="${AVATAR_BORDER_BASE_CLS} ${borderColor}">
				<i class="ph text-invert fs-5 ${icon} ${AVATAR_BASE_CLS} ${bg}"></i>
			</div>`;
};

export const avatarImg = (img = "", alt = "", borderColor = "border-200", width = "h-8", height = "h-8") => {
	return `<div class="${AVATAR_BORDER_BASE_CLS} ${borderColor}">
				<img class="rounded-circle ${borderColor} ${width} ${height}" src="${img}" alt="${alt}" />
			</div>`;
};
