import { throttle } from "../utils";

export const contentHeader = () => {
	const CONTENT_HEADERS = document.querySelectorAll(".content-header");
	const CONTENT = document.getElementById("content");

	const handleScroll = (el) => {
		let lastScrollPosition = 0;
		let scrollEl = el.closest(".simplebar-content-wrapper");

		const throttleScrollHandler = throttle(() => {
			let currentScrollPosition = scrollEl.scrollTop;

			if (currentScrollPosition > lastScrollPosition) {
				el.classList.toggle("scrolling-up", currentScrollPosition > 50);
				el.classList.add("scrolling-down");
			} else {
				el.classList.remove("scrolling-up");
				el.classList.toggle("scrolling-down", currentScrollPosition > 5);
			}

			lastScrollPosition = currentScrollPosition;
		}, 15);

		scrollEl.addEventListener("scroll", () => throttleScrollHandler());
	};

	const observer = new MutationObserver(() => {
		const SCROLL_WRAPPERS = CONTENT.querySelectorAll(".simplebar-content-wrapper");

		if (SCROLL_WRAPPERS.length > 0) {
			CONTENT_HEADERS.forEach((header) => {
				handleScroll(header);
			});
		}
	});

	observer.observe(CONTENT, {
		childList: true,
		subtree: true,
	});
};
