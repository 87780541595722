export const DATA = [
	{
		id: 1,
		subject: "Lorem ipsum dolor sit amet consectetur adipiscing elit",
		from: "Jordan Swanson",
		time: {
			full: "Sun 20/12/2019 10:10",
			short: "10:10",
		},
		img: "1",
		unread: false,
	},
	{
		id: 2,
		subject: "Proin consectetur leo a sem lobortis pulvinar",
		from: "Ivan Mack",
		time: {
			full: "Sun 20/12/2019 09:21",
			short: "09:21",
		},
		img: "15",
		unread: true,
	},
	{
		id: 3,
		subject: "Integer pellentesque lorem et erat aliquet accumsan",
		from: "Marguerite Estrada",
		time: {
			full: "Sun 20/12/2019 06:34",
			short: "06:34",
		},
		cap: "M",
		color: "bg-primary",
		border: "border-primary",
		unread: true,
	},
	{
		id: 4,
		subject: "Suspendisse ut est at urna maximus eleifend",
		from: "Christine Torres",
		time: {
			full: "Sun 20/12/2019 02:55",
			short: "02:55",
		},
		img: "3",
		unread: true,
	},
	{
		id: 5,
		subject: "Quisque cursus ipsum ac commodo ultricies",
		from: "Rafael Daniel",
		time: {
			full: "Sun 20/12/2019 01:02",
			short: "01:02",
		},
		cap: "R",
		color: "bg-danger",
		border: "border-danger",
		unread: true,
	},
	{
		id: 6,
		subject: "Sed scelerisque sapien at vehicula dignissim",
		from: "Nelson Long",
		time: {
			full: "Fri 28/11/2019 22:43",
			short: "28/11",
		},
		img: "4",
		unread: true,
	},
	{
		id: 7,
		subject: "Curabitur vestibulum ipsum non nulla lacinia, at mattis magna blandit",
		from: "Francisco Howell",
		time: {
			full: "Thu 27/11/2019 01:00",
			short: "27/11",
		},
		img: "5",
		unread: false,
	},
	{
		id: 8,
		subject: "Donec ornare elit iaculis elementum rhoncus",
		from: "Wanda Coleman",
		time: {
			full: "Thu 27/11/2019 12:30",
			short: "27/11",
		},
		cap: "W",
		color: "bg-success",
		border: "border-success",
		unread: true,
	},
	{
		id: 9,
		subject: "Morbi id ligula rutrum",
		from: "Terry Ramirez",
		time: {
			full: "Tue 25/11/2019 02:33",
			short: "25/11",
		},
		cap: "T",
		color: "bg-warning",
		border: "border-warning",
		unread: false,
	},
	{
		id: 10,
		subject: "Vestibulum quis arcu eget ipsum condimentum congue",
		from: "Patrick Simmons",
		time: {
			full: "Sat 22/11/2019 12:32",
			short: "22/11",
		},
		img: "6",
		unread: false,
	},
	{
		id: 11,
		subject: "Proin auctor velit et luctus varius",
		from: "Sharon Sanders",
		time: {
			full: "Thu 20/11/2019 24:12",
			short: "20/11",
		},
		cap: "S",
		color: "bg-info",
		border: "border-info",
		unread: false,
	},
	{
		id: 12,
		subject: "Elementum erat vel sem gravida tristique",
		from: "Charles Carter",
		time: {
			full: "Thu 20/11/2019 22:00",
			short: "20/11",
		},
		img: "7",
		unread: false,
	},
	{
		id: 13,
		subject: "Aliquam nec nulla maximus",
		from: "Kenneth Murphy",
		time: {
			full: "Wed 19/11/2019 08:32",
			short: "19/11",
		},
		cap: "K",
		color: "bg-purple",
		border: "border-purple",
		unread: true,
	},
	{
		id: 14,
		subject: "Fusce laoreet dolor tempor metus",
		from: "Janice Washington",
		time: {
			full: "Sat 15/11/2019 04:56",
			short: "15/11",
		},
		img: "8",
		unread: true,
	},
	{
		id: 15,
		subject: "Morbi cursus tortor",
		from: "Douglas Barnes",
		time: {
			full: "Sat 15/11/2019 02:12",
			short: "15/11",
		},
		img: "9",
		unread: true,
	},
	{
		id: 16,
		subject: "Cras at arcu in libero aliquet fringilla",
		from: "Christina Miller",
		time: {
			full: "Wed 12/11/2019 10:10",
			short: "12/11",
		},
		img: "10",
		unread: false,
	},
	{
		id: 17,
		subject: "Vivamus in felis ut ipsum suscipit porttitor tincidunt",
		from: "Marilyn Richardson",
		time: {
			full: "Mon 10/11/2019 20:40",
			short: "10/11",
		},
		cap: "M",
		color: "bg-warning",
		border: "border-warning",
		unread: false,
	},
	{
		id: 18,
		subject: "Fusce semper nisi et lacus bibendum",
		from: "Peter Johnson",
		time: {
			full: "Mon 10/11/2019 19:44",
			short: "10/11",
		},
		cap: "P",
		color: "bg-info",
		border: "border-info",
		unread: true,
	},
	{
		id: 19,
		subject: "Nam in erat non nibh semper euismod at a turpis",
		from: "Helen Powell",
		time: {
			full: "Sun 09/11/2019 05:03",
			short: "09/11",
		},
		img: "11",
		unread: true,
	},
	{
		id: 20,
		subject: "Aenean tristique metus ac commodo vestibulum",
		from: "Tammy Thompson",
		time: {
			full: "Sun 09/11/2019 02:59",
			short: "09/11",
		},
		cap: "T",
		color: "bg-purple",
		border: "border-purple",
		unread: false,
	},
];
