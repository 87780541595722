const _temp0 = require("../../../img/flags/china.svg");
const _temp1 = require("../../../img/flags/england.svg");
const _temp2 = require("../../../img/flags/france.svg");
const _temp3 = require("../../../img/flags/germany.svg");
const _temp4 = require("../../../img/flags/japan.svg");
const _temp5 = require("../../../img/flags/us.svg");
module.exports = {
  "china": _temp0,
  "england": _temp1,
  "france": _temp2,
  "germany": _temp3,
  "japan": _temp4,
  "us": _temp5
}