import { DATA } from "./data";
import contactImages from "../../../img/contacts/*.jpg";
import { avatarCap, avatarImg } from "../../utils";

export const contacts = () => {
	const WRAPPER = document.getElementById("contacts-list");

	if (WRAPPER) {
		DATA.map((item, index) => {
			let listItems = "";
			let avatar = "";
			let active = "";
			let group = document.createElement("div");
			group.id = `contacts-list-${item.group}`;
			group.className = "position-relative";
			WRAPPER.appendChild(group);

			item.items.map((contact, contactIndex) => {
				// Set avatar
				if (contact.img) {
					avatar = avatarImg(contactImages[contact.img], contact.name);
				} else {
					avatar = avatarCap(contact.cap, contact.color, contact.border);
				}

				// Set active class
				index === 0 && contactIndex === 0 ? (active = "bg-active mb-1") : (active = "bg-hover");

				listItems += `<button type="button" data-la-toggle="body" class="${active} d-flex align-items-center w-100 text-start py-2.5 px-3 rounded mb-px">
                                    ${avatar}
                                    <div class="flex-1 overflow-hidden">
                                        <div class="text-body-emphasis truncate mb-0.5">${contact.name}</div>
                                        <div class="text-secondary-emphasis fs-7">${contact.email}</div>
                                    </div>
                                </button>`;
			});

			document.getElementById(`contacts-list-${item.group}`).innerHTML = listItems;
		});
	}
};
